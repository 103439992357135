import { Controller } from "stimulus";

// Usage:
//
// - Main link:
//  <a href="#" data-action="click->fader#toggle">...</a>
// - Content to fadeIn/fadeOut:
//  <div data-target="fader.content" data-action="click->fader#toggle">...</div>

// - "open" class will determine if the content should open. It will be added when the mouse enters,
//    but the open funciton has a timeout. If the mouse leaves, the class will be removed and the open
//    function won't do anything without it.
// - "keep" class will avoid the content to be close. It's used when moving the mouse from the link to the content.


export default class extends Controller {
  static targets = ["content"];

  connect() {
    document.addEventListener("click", this.closeOnClickOutside.bind(this));
  }

  disconnect() {
    document.removeEventListener("click", this.closeOnClickOutside.bind(this));
  }

  toggle(event) {
    event.preventDefault();
    if (this.contentTarget.classList.contains("open")) {
      this._hideContent();
    } else {
      this._showContent();
    }
  }

  show(event) {
    this.contentTarget.classList.add("open", "top-16", "fadeIn");
    this.contentTarget.classList.remove("hidden-top", "fadeOut");
  }

  hide(event) {
    if (!this.contentTarget.contains(event.relatedTarget)) {
      this._hideContent();
    }
  }

  _showContent() {
    this.contentTarget.classList.add("open", "top-16", "fadeIn");
    this.contentTarget.classList.remove("hidden-top", "fadeOut");
  }

  _hideContent() {
    this.contentTarget.classList.remove("open", "fadeIn", "top-16");
    this.contentTarget.classList.add("fadeOut");
    setTimeout(() => {
      this.contentTarget.classList.add("hidden-top");
    }, 250);
  }

  closeOnClickOutside(event) {
    if (!this.element.contains(event.target)) {
      this._hideContent();
    }
  }
}
