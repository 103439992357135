import { Controller } from "stimulus"
import { scrollTo } from "lib/utils"

export default class extends Controller {
  connect() {
    this.handleClickOutside = this.handleClickOutside.bind(this);
    document.addEventListener('click', this.handleClickOutside);
  }

  disconnect() {
    document.removeEventListener('click', this.handleClickOutside);
  }

  reply(event) {
    event.preventDefault();
    event.stopPropagation();
    
    const replyLinkElement = event.target;
    const targetId = replyLinkElement.dataset.replyTargetId;
    const targetForm = document.querySelector(`[data-form-target-id="${targetId}"]`);
    const isNestedForm = targetId.startsWith('Comment#');

    // Ocultar todos los formularios
    const allForms = document.querySelectorAll('[data-form-target-id]');
    allForms.forEach(form => form.classList.add('hidden'));

    // Manejar visibilidad del botón de comentario principal
    const mainCommentButton = document.querySelector('[data-comment-button]');
    if (mainCommentButton) {
      if (isNestedForm) {
        mainCommentButton.classList.remove('hidden');
      } else {
        mainCommentButton.classList.add('hidden');
      }
    }

    // Mostrar el formulario objetivo
    targetForm.classList.remove('hidden');
    scrollTo(targetForm);
  }

  handleClickOutside(event) {
    // Solo seleccionamos los formularios de respuestas (que tienen Comment# en su target-id)
    const replyForms = document.querySelectorAll('[data-form-target-id^="Comment#"]');
    replyForms.forEach(form => {
      if (!form.classList.contains('hidden') && !form.contains(event.target) && !event.target.closest('[data-action*="commentable#reply"]')) {
        form.classList.add('hidden');
        // Mostrar el formulario principal cuando se cierra un formulario anidado
        const mainForm = document.querySelector('[data-form-target-id^="Article#"], [data-form-target-id^="Question#"]');
        const mainCommentButton = document.querySelector('[data-comment-button]');
        if (mainForm) {
          mainForm.classList.remove('hidden');
          if (mainCommentButton) mainCommentButton.classList.add('hidden');
        }
      }
    });
  }
}
