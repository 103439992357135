// Add the calculator's JS code to the Webpack asset pipeline inside the "modules" folder,
// so that the application_v2 layout can be used on both calculators in the simplest possible way.
// I have refactored the original jQuery code into Vanilla JS (to avoid installing jQuery).
// Convert this into a Stimulus controller in the future (and update both calculators' views).

// Import the necessary dependencies
import AirDatepicker from 'air-datepicker'
import 'air-datepicker/air-datepicker.css'
import localeEs from 'air-datepicker/locale/es'

// Closure cerrado para evitar interacciones en el scope global
const CONFIGURATION = {
  FACTORHORAS: 7.5,
  HORAINICIOTUTORIA: 20,
  TEXTONODISPONIBLE: "Este Curso aún no está a la venta, ¡aunque lo estará en breve!.",
  TEXTONOBONIFICABLE: "Este Curso, por sus características, no puede ser bonificado.",
  TEXTOTIEMPOREPASO: "Con el tiempo que te sobra podrás aprovechar para repasar y consultar tranquilamente lo estudiado.",
  TRABAJADORES_MENOS_6: 0,
  TRABAJADORES_DE_6_A_9: 5,
  TRABAJADORES_DE_10_A_49: 10,
  TRABAJADORES_DE_50_A_249: 20,
  TRABAJADORES_DE_250_A_MAS: 40,
  LISTAEMPLEADOSITEM1: "De 1 a 5 trabajadores",
  LISTAEMPLEADOSITEM2: "De 6 a 9 trabajadores",
  LISTAEMPLEADOSITEM3: "De 10 a 49 trabajadores",
  LISTAEMPLEADOSITEM4: "De 50 a 249 trabajadores",
  LISTAEMPLEADOSITEM5: "250 o más trabajadores",
  UMBRALPREMIO: 30,
  UMBRALTIEMPOREPASO: 10,
  UMBRALEXCESO: 35,
  FACTORMULTTIEMPOS: 3, // Valor original antes de ajuste: 4.5
  TEXTOPREMIO: "Como te sobra bastante tiempo, puedes elegir entre dos opciones:",
  TEXTOPREMIO_OP1: "* aprovechar ese tiempo para repasar y consultar tranquilamente lo estudiado",
  TEXTOEXCESO: "Tendrías que revisar tu plan de dedicaciones para poder completar el curso en el tiempo estipulado.",
  DTOPREMIO: 15,
  TEXTOSEMANANOCOMPLETA: "Por favor completa tus dedicaciones para poder continuar",
  TEXTOTIEMPOSUFICIENTE: "Como ves, tienes tiempo de sobra para completar el curso... ¿te animas?",
  TEXTOPLANRELAX: "Relajado:",
  TEXTOPLANNORMAL: "Clásico:",
  TEXTOPLANRETO: "Reto:",
  HORASPLANRELAX: 2,
  HORASPLANNORMAL: 4,
  HORASPLANRETO: 6,
  CODIGOCUPON: "&coupon_code=KRTO_TX123"
};

let COURSES = [];

// DOM manipulation functions
function fillCourses(elem, options) {
  if (!options.length) return;

  const optionsHTML = options
    .filter(option => option.hasOwnProperty("title"))
    .map(option => `<option value="${option.id}">${option.title}</option>`)
    .join('');
  
  elem.innerHTML = optionsHTML;
}

function fillEmployees(elem, options) {
  if (!options.length) return;

  const optionsHTML = options
    .map((option, index) => `<option value="${index}">${CONFIGURATION[option]}</option>`)
    .join('');
  
  elem.innerHTML = optionsHTML;
}

function fillPlans(elem, options) {
  if (!options.length) return;

  const optionsHTML = options.map(option => {
    const hours = option[Object.keys(option).find(n => /HORAS/.test(n))];
    const text = option[Object.keys(option).find(n => /TEXTO/.test(n))];
    return `<option value="${hours}">${text} ${hours}h/semana</option>`;
  }).join('');

  elem.innerHTML = optionsHTML;
}

function getBonus(hours, price, financing) {
  const bonus = Math.min(
    hours * CONFIGURATION.FACTORHORAS,
    price * (1 - toPercent(financing))
  );
  const discount = bonus / price;

  return { bonus, discount };
}

function getTiming(video, work, max) {
  const weeks = Math.round((video * CONFIGURATION.FACTORMULTTIEMPOS) / work);
  const months = Math.round(weeks / 4.33, 1);
  const reduced = Math.round(
    max * 4.33 * (1 - toPercent(CONFIGURATION.UMBRALPREMIO))
  );
  const extra = Math.floor(weeks - (max * 4.33));

  return { weeks, months, reduced, extra };
}

function getDates(start, weeks, months) {
  if (!start) return;

  const estimatedEndDate = new Date(start).addDays(weeks * 7);
  const maxAvailableDate = new Date(start).addDays(months * 4.33 * 7);
  let monthsToGo = 0;

  if (maxAvailableDate < estimatedEndDate) {
    monthsToGo = 0;
  } else {
    monthsToGo = estimatedEndDate.monthDiff(maxAvailableDate);
  }

  return { estimatedEndDate, monthsToGo, maxAvailableDate };
}

function pick(obj, keys) {
  return keys.reduce((acc, key) => {
    if (key in obj) {
      acc[key] = obj[key];
    }
    return acc;
  }, {});
}

function toPercent(percent) {
  if (typeof percent === "string") percent = parseInt(percent);
  if (percent < 0) percent = 0;
  if (percent > 1 && percent < 100) percent = percent / 100;
  return percent;
}

function parseDate(d) {
  if (!d) return;
  const [day, month, year] = d.split(/\//);
  return new Date(`${month}/${day}/${year}`);
}

// Extend Date prototype
Date.prototype.addDays = function(days) {
  const date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
};

Date.prototype.monthDiff = function(_d) {
  const date = new Date(this.valueOf());
  const d = new Date(_d);
  let months = (d.getFullYear() - date.getFullYear()) * 12;
  months -= date.getMonth() + 1;
  months += d.getMonth();
  const day1 = 30 - date.getDate();
  const day2 = day1 + d.getDate();
  months += parseInt(day2 / 30);
  return months <= 0 ? 0 : months;
};

function setText(element, text) {
  if (element) {
    element.textContent = text;
  }
}

function resetCustomPlan() {
  const daysElements = document.querySelectorAll("[class*=js-fundae_plans_]");
  daysElements.forEach(elem => {
    elem.value = 0;
  });
}

function getWorkHours(plansElement, daysElements) {
  // Si el panel personalizado está visible, usar esas horas
  if (!document.querySelector('.js-toggle').classList.contains('hidden')) {
    let customHours = 0;
    daysElements.forEach(elem => {
      customHours += Number(elem.value);
    });
    return customHours;
  }
  
  // Si no, usar el valor del selector de plan
  return Number(plansElement?.value || CONFIGURATION.HORASPLANNORMAL);
}

function update() {
  const selector = {
    c: Number(document.querySelector(".js-fundae_courses")?.value || 0),
    e: Number(document.querySelector(".js-fundae_employees")?.value || 0),
    p: Number(document.querySelector(".js-fundae_plans")?.value || CONFIGURATION.HORASPLANNORMAL),
    d: parseDate(document.querySelector(".js-fundae_date")?.value)
  };

  const employeesElement = document.querySelector(".js-fundae_employees");
  const plansElement = document.querySelector(".js-fundae_plans");

  // courses
  const course = COURSES.find(element => element.id == selector.c);
  const weeksElements = document.querySelectorAll(".js-fundae_weeks");
  const priceElements = document.querySelectorAll(".js-fundae_price");
  const deadlineElement = document.querySelector(".js-fundae_deadline");
  const tutorElements = document.querySelectorAll(".js-fundae_hours");

  // Update all instances of weeks and hours
  weeksElements.forEach(element => {
    setText(element, course?.fundae_weeks || 0);
  });

  const courseElement = document.querySelector(".js-fundae_course");
  if (courseElement) {
    setText(courseElement, course?.title || "");
  }

  tutorElements.forEach(element => {
    setText(element, course?.fundae_hours || 0);
  });
  priceElements.forEach(element => {
    setText(element, (Number(course?.price) || 0).toLocaleString());
  });
  setText(deadlineElement, course?.months || 0);

  // employees
  if (employeesElement) {
    const finalElement = document.querySelector(".js-fundae_calc_price");
    const bonificacionElement = document.querySelector(".js-bonus");
    const discountElement = document.querySelector(".js-fundae_calc_discount");

    const employees = Object.keys(CONFIGURATION).filter(n => /TRABAJADORES/.test(n));
    const discount = CONFIGURATION[employees[selector.e]];

    const bonus = getBonus(course?.fundae_hours, course?.price, discount);
    const bonificacion = bonus.bonus;
    const final = course?.price - bonificacion;

    setText(bonificacionElement, bonificacion?.toLocaleString());
    setText(finalElement, final?.toLocaleString());
    setText(discountElement, bonus.discount?.toLocaleString(undefined, { style: "percent" }));
  }

  // plans
  if (plansElement) {
    const calcWeeksElement = document.querySelector(".js-fundae_calc_weeks");
    const monthsElement = document.querySelector(".js-fundae_calc_months");
    const reducedElement = document.querySelector(".js-fundae_reduce_weeks");
    const endDateElement = document.querySelector(".js-fundae_end_date");
    const togoElement = document.querySelector(".js-fundae_date_to_go");
    const endingElement = document.querySelector(".js-fundae_ending");
    const exceedElement = document.querySelector(".js-fundae_time_exceed");
    const buttonsElement = document.querySelector(".js-fundae_sale_buttons");
    const reduceButtonElement = document.querySelector(".js-fundae_reduced_sale_button");
    const presaleElement = document.querySelector(".js-fundae_presale");
    const proposalElement = document.querySelector(".js-fundae_proposal");

    const dateElement = document.querySelector(".js-fundae_date");
    const daysElements = document.querySelectorAll("[class*=js-fundae_plans_]");

    // Obtener las horas de trabajo (personalizadas o del selector)
    const work = getWorkHours(plansElement, daysElements);

    const selectedElement = document.querySelector('.js-selected');
    if (selector.d && work > 0) {
      selectedElement?.classList.remove('hidden');
    } else {
      selectedElement?.classList.add('hidden');
    }

    // presale
    if (course?.presale) {
      presaleElement?.classList.remove('hidden');
      if (dateElement) dateElement.disabled = true;
      if (plansElement) plansElement.disabled = true;
      setText(presaleElement, CONFIGURATION.TEXTONODISPONIBLE);
      selectedElement?.classList.add('hidden');
      return;
    } else {
      presaleElement?.classList.add('hidden');
      if (dateElement) dateElement.disabled = false;
      if (plansElement) plansElement.disabled = false;
    }

    if (!work) return;

    const timing = getTiming(course?.video_hours, work, course?.months);
    setText(calcWeeksElement, timing.weeks || 0);
    setText(monthsElement, timing.months || 0);
    setText(reducedElement, timing.reduced || 0);

    const dates = getDates(selector.d, timing.weeks, course?.months);

    setText(endingElement, CONFIGURATION.TEXTOTIEMPOREPASO);
    if (dates?.estimatedEndDate) {
      setText(endDateElement, dates.estimatedEndDate.toLocaleDateString(undefined, {
        month: "short",
        day: "numeric",
        year: "numeric"
      }));
    }

    const togo = dates?.monthsToGo || 0;

    if (togo && exceedElement) {
      exceedElement.classList.remove('hidden');
      setText(togoElement, togo);
    } else if (exceedElement) {
      exceedElement.classList.add('hidden');
    }

    // Cases
    if (dates?.estimatedEndDate > dates?.maxAvailableDate) {
      // 1. No time
      buttonsElement?.classList.add('hidden');
      setText(proposalElement, CONFIGURATION.TEXTOEXCESO);
    } else if (timing.weeks <= timing.reduced) {
      // 2. Plenty of time and discount offered
      buttonsElement?.classList.remove('hidden');
      reduceButtonElement?.classList.remove('hidden');
      setText(proposalElement, CONFIGURATION.TEXTOPREMIO);
    } else {
      // 3. Just enough time
      buttonsElement?.classList.remove('hidden');
      reduceButtonElement?.classList.add('hidden');
      setText(proposalElement, CONFIGURATION.TEXTOTIEMPOSUFICIENTE);
    }

    const regularProductLink = document.querySelector(".js-regular-product-link");
    const couponProductLink = document.querySelector(".js-coupon-product-link");
    
    if (regularProductLink && course?.regular_product_buy_url) {
      regularProductLink.href = course.regular_product_buy_url;
    }
    if (couponProductLink && course?.coupon_product_buy_url) {
      couponProductLink.href = course.coupon_product_buy_url;
    }
  }
}

// Initialize on document load
document.addEventListener('turbolinks:load', () => {
  const actionName = document.body.getAttribute("action-name");
  if (actionName !== "calculadora_fundae" && actionName !== "calculadora_tiempos") {
    return;
  }

  // Initialize datepicker
  const datepickerElement = document.querySelector('.js-fundae_date');
  if (datepickerElement) {
    const today = new Date();
    const formattedDate = `${String(today.getDate()).padStart(2, '0')}/${String(today.getMonth() + 1).padStart(2, '0')}/${today.getFullYear()}`;
    
    // Set today's date as the default value
    datepickerElement.value = formattedDate;
    
    new AirDatepicker(datepickerElement, {
      locale: localeEs,
      dateFormat: 'dd/MM/yyyy',
      minDate: new Date(),
      autoClose: true,
      position: 'bottom left',
      selectedDates: [today], // Pre-select today's date
      onSelect: () => update()
    });
    
    // Trigger update to reflect the default date
    setTimeout(update, 100);
  }

  // Fetch courses data
  fetch('/api/courses')
    .then(response => response.json())
    .then(response => {
      COURSES = response;

      const coursesElement = document.querySelector(".js-fundae_courses");
      const employeesElement = document.querySelector(".js-fundae_employees");
      const plansElement = document.querySelector(".js-fundae_plans");
      const dateElement = document.querySelector(".js-fundae_date");

      if (coursesElement) {
        coursesElement.addEventListener('change', update);
      }

      if (employeesElement) {
        // Fill only needed elements
        fillCourses(
          coursesElement,
          COURSES.filter(n => n.video_hours && n.fundae_hours && n.fundae_weeks)
        );
        fillEmployees(
          employeesElement,
          Object.keys(CONFIGURATION).filter(n => /LISTAEMPLEADOSITEM/.test(n))
        );

        employeesElement.addEventListener('change', update);
      }

      if (plansElement) {
        fillCourses(coursesElement, COURSES);

        // Create array with reduced CONFIGURATION properties
        const map = [
          pick(
            CONFIGURATION,
            Object.keys(CONFIGURATION).filter(n => /PLANRELAX/.test(n))
          ),
          pick(
            CONFIGURATION,
            Object.keys(CONFIGURATION).filter(n => /PLANNORMAL/.test(n))
          ),
          pick(
            CONFIGURATION,
            Object.keys(CONFIGURATION).filter(n => /PLANRETO/.test(n))
          )
        ];

        fillPlans(plansElement, map);
        
        // Establecer el plan por defecto (Clásico)
        plansElement.value = CONFIGURATION.HORASPLANNORMAL;

        plansElement.addEventListener('change', () => {
          // Si el panel personalizado está visible, ocultarlo y resetear valores
          const customPanelVisible = !document.querySelector('.js-toggle').classList.contains('hidden');
          if (customPanelVisible) {
            document.querySelectorAll(".js-toggle").forEach(toggleElement => {
              toggleElement.classList.add("hidden");
            });
            resetCustomPlan();
          }
          update();
        });
        
        if (dateElement) {
          dateElement.addEventListener('change', update);
        }

        const daysElements = document.querySelectorAll("[class*=js-fundae_plans_]");
        daysElements.forEach(elem => {
          elem.value = 0;
          elem.addEventListener('change', update);
        });
      }

      update();
    })
    .catch(() => {
      throw new Error('No se ha podido obtener información de los cursos.');
    });

  // Toggle functionality
  document.querySelectorAll("[data-toggle]").forEach(element => {
    element.addEventListener('click', () => {
      document.querySelectorAll(".js-toggle").forEach(toggleElement => {
        toggleElement.classList.toggle("hidden");
        
        // Si estamos ocultando el panel personalizado, resetear los valores
        if (toggleElement.classList.contains('hidden')) {
          resetCustomPlan();
        }
      });
      update();
    });
  });
}); 
